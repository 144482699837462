import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom"
import moment from "moment-timezone";
import axios from 'axios';
import Timeline, { TimelineMarkers, CustomMarker, TimelineHeaders, SidebarHeader, DateHeader } from 'react-calendar-timeline'
import './../style.css'

function ScheduleTimeline(){
    const [ params ] = useSearchParams();
    // console.log(params.get('node'));
    // console.log(params.get('resourceId'));
    // console.log(params.get('startTime'));
    // console.log(params.get('endTime'));

    const [groups, setGroups] = useState([]);
    const [items, setItems]   = useState([]);

    function showCalendar(node, resourceId, startTime, endTime) {
        const options = {
            method: 'POST',
            url: `${process.env.REACT_APP_OS_URL}/graphql`,
            headers: {
                'content-type': 'application/json',
            },
            data: {
                query: `query ($node: String!, $resourceIds: [Int]!, $data: TimespanInput!) {
                    timespans(node: $node, resourceIds: $resourceIds, data: $data) {
                        id
                        resourceId
                        name
                        startTime
                        endTime
                        resource {
                            id
                            name
                        }
                    }
                }`,variables:{
                    node: node,
                    resourceIds:resourceId.split(",").map(Number),
                    data:{
                        startTime: startTime,
                        endTime: endTime
                    }
                }
            }
        };
        axios
            .request(options)
            .then(function (response) {
                const res = response.data; // Response received from the API
                console.log(res.data);
        
                var _groups = [];
                res.data.timespans.forEach((item, index) => {
                    if(_groups.findIndex(x => x.id === item.resource.id) === -1){
                        _groups.push({
                            id: item.resource.id,
                            title: item.resource.name + '*',
                        });
                    }
                });
                setGroups(old => [...old, ..._groups]);
                console.log(_groups)

                var _items = res.data.timespans.map((item, index) => {
                    return {
                        id: item.id ,
                        group: item.resourceId,
                        title: item.name + " [" + moment(item.startTime).format("HH:mm") + " - " + moment(item.endTime).format("HH:mm") + "]",
                        tip: 'additional information',
                        start_time: moment(item.startTime),
                        end_time: moment(item.endTime),
                        itemProps: {
                            style: {
                                background: 'black',
                                fontSize: '5px'
                            },
                            "data-tip": "tooltip",
                        }
                    }
                });
                _items = _items.sort(function(a, b) { return b - a; })
                setItems(old => [...old, ..._items]);
                
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    useEffect(() => {
        showCalendar(params.get('node'), params.get('resourceId'), params.get('startTime'), params.get('endTime'));
    }, [params]);

    const defaultTimeStart = moment()
        .startOf("day")
        .add(-7, "day")
        .toDate();
    const defaultTimeEnd = moment()
        .startOf("day")
        .add(7, "day")
        .toDate();

    return (
        (groups.length > 0 && items.length > 0) ? 
            <Timeline
                groups={groups}
                items={items}
                fixedHeader="fixed"
                stackItems={true}
                sidebarContent={<div>Above The Left</div>}
                itemsSorted
                itemTouchSendsClick={false}
                itemHeightRatio={0.75}
                showCursorLine={true}
                canMove={false}
                canResize={true}
                timeSteps={{
                    minute: 15,
                    hour: 1,
                    day: 1,
                    month: 1,
                    year: 1
                }}
                keys={{
                    groupIdKey: 'id',
                    groupTitleKey: 'title',
                    groupRightTitleKey: 'rightTitle',
                    itemIdKey: 'id',
                    itemTitleKey: 'title',    // key for item div content
                    itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
                    itemGroupKey: 'group',
                    itemTimeStartKey: 'start_time',
                    itemTimeEndKey: 'end_time'
                }}
                minZoom={3 * 60 * 60 * 1000}
                maxZoom={1 * 60 * 86400 * 1000}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                onBoundsChange={(canvasTimeStart, canvasTimeEnd)=>{
                    console.log(moment(canvasTimeStart).format("YYYY-MM-DD"));
                    console.log(moment(canvasTimeEnd).format("YYYY-MM-DD"));
                    showCalendar(params.get('node'), params.get('resourceId'), moment(canvasTimeStart).format("YYYY-MM-DD"), moment(canvasTimeEnd).format("YYYY-MM-DD"));
                }}
            >
                <TimelineHeaders>
                    <SidebarHeader>
                        {({ getRootProps }) => {
                            return <div {...getRootProps()}></div>
                        }}
                    </SidebarHeader>
                    {/* <SidebarHeader variant="right" headerData={{}}>
                        {({ getRootProps, data }) => {
                            return <div {...getRootProps()}></div>
                        }}
                    </SidebarHeader> */}
                    <DateHeader unit="primaryHeader" />
                    <DateHeader /> 
                </TimelineHeaders>
                <TimelineMarkers>
                    <CustomMarker date={Date.now()} >
                        {({ styles, date }) => {
                            const customStyles = {
                                ...styles,
                                backgroundColor: 'red',
                                width          : '4px'
                            }
                            return <div style={customStyles} onClick={()=>{}} />
                        }}
                    </CustomMarker>
                </TimelineMarkers>
            </Timeline>
        : (<div id='loading' className='loading'>Loading...</div>)
    );    
}
export default ScheduleTimeline;